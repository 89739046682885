<template>

    <div class="p-grid panel-demo">

            <div class="p-col-12 p-md-12">
                <div class="card p-fluid">
                    <h1>
                        Incident Alert
                    </h1>
                    <h2>Alert ArenaCX to a system outage or other emergency</h2>
             
                    <div class="paragraph">
                        Email <a href="mailto:incidentalert@arenacx.com?body=Provide your contact details, with details of the issue&subject=ArenaCX Incident Alert!">incidentalert@arenacx.com</a> to alert our Customer and IT teams to the issue.
                    </div>

                    <div class="paragraph">
                        You can also call <span class="phone">(833) 273-6229</span> and use <span class="phone">extension 5</span> to alert our team directly.
                    </div>
                </div>
            </div>
    </div>
 
        
        
     
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style   scoped>


a {
    font-weight: bold;
    color: #c43b29;
}

h1 {
    color: #333333;
    margin-bottom: 15px
}

h2 {
    color: #c95546;
    font-size: 18px;
    margin-top: 10px;
    border-bottom: 1px solid #292B32;
    letter-spacing: 0.05rem;
    font-style: italic;
    width: 50%
}

.paragraph {
    margin: 13px;
    margin-top: 26px;
    font-size: 110%;
}

.phone {
    font-weight: bold;
    color: #292B32;
}
 

</style>